import { lazy } from "react";
import { createRoutesFromElements, Route, Outlet, useOutletContext } from 'react-router-dom';
import ApplicationLayout from '@src/layouts/ApplicationLayout';
import UserSessionsLayout from '@src/layouts/UserSessionsLayout';
import SignIn from '@src/routes/User/SignIn';
import { applicationData } from '@src/util/environment';
import ProtectedRoute from './ProtectedRoute'
const { params } = applicationData;

const Root = lazy(() => import('@src/routes/Root'));

const Reservation = {
  Index: lazy(() => import('@src/routes/Reservation/Index')),
  Show: lazy(() => import('@src/routes/Reservation/Show')),
};

const Property = {
  Index: lazy(() => import('@src/routes/Property/Index')),
  Show: lazy(() => import('@src/routes/Property/Show')),
};

const User = {
  SignIn,
  NewPassword: lazy(() => import('@src/routes/User/NewPassword')),
  EditPassword: lazy(() => import('@src/routes/User/EditPassword')),
  Index: lazy(() => import('@src/routes/User/Index')),
  Register: lazy(() => import('@src/routes/User/Register')),
  Show: lazy(() => import('@src/routes/User/Show')),
  Edit: lazy(() => import('@src/routes/User/Edit')),
  Group: {
    Index: lazy(() => import('@src/routes/User/Group/Index')),
    Register: lazy(() => import('@src/routes/User/Group/Register')),
    Show: lazy(() => import('@src/routes/User/Group/Show')),
    Edit: lazy(() => import('@src/routes/User/Group/Edit')),
  }
};

const Housekeeping = {
  Shift: {
    Recruitment: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Recruitment/Index')),
      Show: lazy(() => import('@src/routes/Housekeeping/Shift/Recruitment/Show')),
      Post: lazy(() => import('@src/routes/Housekeeping/Shift/Recruitment/Register')),
      Edit: lazy(() => import('@src/routes/Housekeeping/Shift/Recruitment/Edit')),
    },
    Request: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Request/Index')),
      New: lazy(() => import('@src/routes/Housekeeping/Shift/Request/New')),
      Edit: lazy(() => import('@src/routes/Housekeeping/Shift/Request/Edit')),
    },
    Assignment: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Assignment/Index')),
    },
    Confirmation: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Confirmation/Index')),
    },
    Pattern: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Pattern/Index')),
      Show: lazy(() => import('@src/routes/Housekeeping/Shift/Pattern/Show')),
      Register: lazy(() => import('@src/routes/Housekeeping/Shift/Pattern/Register')),
      Edit: lazy(() => import('@src/routes/Housekeeping/Shift/Pattern/Edit')),
      Property: {
        Edit: lazy(() => import('@src/routes/Housekeeping/Shift/Pattern/Property/Edit')),
      }
    },
    Option: {
      Index: lazy(() => import('@src/routes/Housekeeping/Shift/Option/Index')),
      Show: lazy(() => import('@src/routes/Housekeeping/Shift/Option/Show')),
      Register: lazy(() => import('@src/routes/Housekeeping/Shift/Option/Register')),
      Edit: lazy(() => import('@src/routes/Housekeeping/Shift/Option/Edit')),
    },
  },
  Room: {
    Assignment: {
      Index: lazy(() => import('@src/routes/Housekeeping/Room/Assignment/Index')),
    },
  },
  Execution: {
    Index: lazy(() => import('@src/routes/Housekeeping/Execution/Index')),
    Show: lazy(() => import('@src/routes/Housekeeping/Execution/Show')),
  },
  Calendar: {
    Index: lazy(() => import('@src/routes/Housekeeping/Calendar/Index')),
  }
};

const Message = {
  Template: {
    Index: lazy(() => import('@src/routes/Message/Template/Index')),
    Show: lazy(() => import('@src/routes/Message/Template/Show')),
  },
  Schedule: {
    Index: lazy(() => import('@src/routes/Message/Schedule/Index')),
    Show: lazy(() => import('@src/routes/Message/Schedule/Show')),
  },
  DeliveryLog: {
    Index: lazy(() => import('@src/routes/Message/Delivery/Log/Index')),
    Show: lazy(() => import('@src/routes/Message/Delivery/Log/Show')),
  },
};

const routes = createRoutesFromElements(<>
  <Route path="/" element={<ApplicationLayout />} errorElement={<ApplicationLayout notFound />}>
    <Route index element={<Root />} />

    <Route path="reservations" element={<Outlet />}>
      <Route index element={<Reservation.Index />} />
      <Route path="future" element={<Reservation.Index future />} />
      <Route path="staying" element={<Reservation.Index staying />} />
      <Route path="unsent" element={<Reservation.Index unsent />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<Reservation.Show />} />
      </Route>
    </Route>

    <Route path="properties" element={<Outlet />}>
      <Route index element={<Property.Index />} />
      <Route path=":id" element={<Property.Show />} />
    </Route>

    <Route path="users" element={<Outlet />}>
      <Route index element={<User.Index />} />
      <Route path="new" element={<User.Register />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<User.Show />} />
        <Route path="edit" element={<User.Edit />} />
      </Route>
    </Route>

    <Route path="user/groups" element={<Outlet />}>
      <Route index element={<User.Group.Index />} />
      <Route path="new" element={<User.Group.Register />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<User.Group.Show />} />
        <Route path="edit" element={<User.Group.Edit />} />
      </Route>
    </Route>

    <Route path="housekeeping/shift/recruitments" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Recruitment.Index />} />
      <Route path="new" element={<Housekeeping.Shift.Recruitment.Post />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<Housekeeping.Shift.Recruitment.Show />} />
        <Route path="edit" element={<Housekeeping.Shift.Recruitment.Edit />} />
      </Route>
    </Route>

    <Route path="housekeeping/shift/requests" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Request.Index />} />
      <Route path="new" element={<Housekeeping.Shift.Request.New />} />
      <Route path=":id" element={<Housekeeping.Shift.Request.Edit />} />
    </Route>

    <Route path="housekeeping/shift/assignments" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Assignment.Index />} />
    </Route>


    <Route path="housekeeping/shift/confirmations" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Confirmation.Index />} />
    </Route>

    <Route path="housekeeping/room/assignments" element={<Outlet />}>
      <Route index element={<Housekeeping.Room.Assignment.Index />} />
    </Route>

    <Route path="housekeeping/executions" element={<Outlet />}>
      <Route index element={<Housekeeping.Execution.Index />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<Housekeeping.Execution.Show />} />
      </Route>
    </Route>

    <Route path="housekeeping/calendars" element={<Outlet />}>
      <Route index element={<Housekeeping.Calendar.Index />} />
    </Route>

    <Route path="housekeeping/shift/patterns" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Pattern.Index />} />
      <Route path="new" element={<Housekeeping.Shift.Pattern.Register />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<Housekeeping.Shift.Pattern.Show />} />
        <Route path="edit" element={<Housekeeping.Shift.Pattern.Edit />} />
      </Route>
    </Route>

    <Route path="housekeeping/shift/pattern/properties" element={<Outlet />}>
      <Route path=":id" element={<Outlet />}>
        <Route path="edit" element={<Housekeeping.Shift.Pattern.Property.Edit />} />
      </Route>
    </Route>

    <Route path="housekeeping/shift/options" element={<Outlet />}>
      <Route index element={<Housekeeping.Shift.Option.Index />} />
      <Route path="new" element={<Housekeeping.Shift.Option.Register />} />
      <Route path=":id" element={<Outlet />}>
        <Route index element={<Housekeeping.Shift.Option.Show />} />
        <Route path="edit" element={<Housekeeping.Shift.Option.Edit />} />
      </Route>
    </Route>

    <Route path="message/templates" element={<Outlet />}>
      <Route index element={<Message.Template.Index />} />
      <Route path=":id" element={<Message.Template.Show />} />
    </Route>

    <Route path="message/schedules" element={<Outlet />}>
      <Route index element={<Message.Schedule.Index />} />
      <Route path=":id" element={<Message.Schedule.Show />} />
    </Route>

    <Route path="message/delivery/logs" element={<Outlet />}>
      <Route index element={<Message.DeliveryLog.Index />} />
      <Route path=":id" element={<Message.DeliveryLog.Show />} />
    </Route>
  </Route >

  <Route path="/users" element={<UserSessionsLayout />}>
    <Route path="sign-in" element={<User.SignIn />} />
    <Route path="password/new" element={<User.NewPassword />} />
    <Route path="password" element={params.action == "create" ? <User.NewPassword /> : <User.EditPassword />} />
    <Route path="password/edit" element={<User.EditPassword />} />
  </Route>
</>)

export default routes;
