import ApplicationModel from '@src/models/ApplicationModel';

export default class Property extends ApplicationModel {
  get neppanPrimaryAccount() {
    if (!this.neppanAccounts) { return }

    return this.neppanAccounts.find(a => a.enabledApis.includes('pms')) ||
      this.neppanAccounts.find(a => a.enabledApis.includes('other_checkin_ea')) ||
      this.neppanAccounts.find(a => a.enabledApis.includes('other_checkin')) ||
      this.neppanAccounts[0];
  }
}
