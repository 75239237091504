import { useState, useEffect } from 'react';
import { applicationData } from '@src/util/environment';

const script = document.createElement('script');
script.src = "https://apis.google.com/js/api.js";
script.async = true;
script.defer = true;
let clientInitialized = false;

export const useGAPI = () => {
  const [gapi, setGAPI] = useState(null);

  useEffect(() => {
    if (!script.parentNode) {
      script.onload = () => {
        window.gapi.load('client', async () => {
          try {
            window.gapi.client.setApiKey(applicationData.googleAPIKey);

            await window.gapi.client.load('calendar', 'v3');

            clientInitialized = true;
            setGAPI(window.gapi);
          } catch (error) {
            console.error("Failed to initialize Google API client:", error);
            setGAPI(null);
          }
        });
      };
      document.body.appendChild(script);
    } else if (!gapi && clientInitialized) {
      setGAPI(window.gapi);
    }
  }, []);

  return gapi;
};

/**
 * @example
 * import { useGoogleCalendar } from '@src/util/gapi';
 *
 * const { items, isLoading } = useGoogleCalendar(
 *   'ja.japanese%23holiday@group.v.calendar.google.com',
 *   {
 *     timeMin: dayjs().startOf('day').toISOString(),
 *     timeMax: dayjs().add(1, 'year').startOf('day').toISOString(),
 *   }
 * );
 *
 * @see https://developers.google.com/calendar/api/v3/reference/events/list for query parameters
 */
export const useGoogleCalendar = (calendarId, params) => {
  const gapi = useGAPI();
  const query = new URLSearchParams(params);
  const [state, setState] = useState({ items: null, isLoading: true });

  useEffect(() => {
    if (gapi === null) {
      setState({ items: null, error: "Failed to load Google API", isLoading: false });
    } else if (gapi) {
      setState({ items: null, isLoading: true });

      gapi.client.calendar.events.list({
        calendarId,
        ...params,
      }).then((response) => {
        if (response.status === 200) {
          setState({ items: response.result.items, isLoading: false });
        } else {
          setState({ error: response.result?.error, isLoading: false });
        }
      }).catch((error) => {
        console.error("Google Calendar API error:", error);
        setState({ error, isLoading: false });
      });
    }
  }, [gapi, query.toString()]);

  return state;
};
