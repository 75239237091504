export default ({
  SidebarMenu: {
    "Dashboard": "Dashboard",
    "Home": "Home",
    "Reservation Management": "Reservation Management",
    "Reservations": "Reservations",
    "All Reservations": "All Reservations",
    "Future Reservations": "Future Reservations",
    "Staying Reservations": "Staying Reservations",
    "Property Management": "Property Management",
    "Properties": "Properties",
    "User Management": "User Management",
    "Users": "Users",
    "Groups": "Groups",
    "Housekeeping Management": "Housekeeping Management",
    "Shift Recruitment": "Shift Recruitment",
    "Shift Request": "Shift Request",
    "Shift Assignment": "Shift Assignment",
    "Shift Confirmation": "Shift Confirmation",
    "Shift Pattern": "Shift Group",
    "Room Assignment": "Room Assignment",
    "Cleaning Execution": "Cleaning Execution",
    "Contents Management": "Contents Management",
    "Message Management": "Message Management",
    "Message Templates": "Templates",
    "Message Schedule": "Schedules",
    "Message Delivery Logs": "Delivery Logs",
    "Unsent Reservations": "Message Unsent",
  },
  "Language": "Language",
});
