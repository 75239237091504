import ApplicationModel from '@src/models/ApplicationModel';
import Property from "@src/models/Property";
import UserGroup from "@src/models/UserGroup";

export default class User extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);
    this.properties = this.properties?.map(property => new Property(property));
    this.groups = this.groups?.map(group => new UserGroup(group));
  }

  authorize(roleName) {
    return this.roles?.find(role => role.name === roleName);
  }

  accessLevel(roleName) {
    return this.authorize(roleName)?.role;
  }
}
