import { useState, useCallback, useEffect } from 'react';
import { useGAPI } from '@src/util/gapi';
import useSWRMutation from 'swr/mutation';
import dayjs from 'dayjs';

const fetcher = async (key, options = {}) => {
  const [gapi, calendarId] = key;
  if (!gapi) { return; }

  const { arg } = options;
  const params = new URLSearchParams({
    singleEvents: true,
    orderBy: 'startTime',
    ...arg,
  });

  const response = await gapi.client.request({
    path: `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}%40group.v.calendar.google.com/events?${params}`
  });

  return response;
};

/**
 * @example
 * const getHoliday = useHolidays('ja.japanese.official#holiday');
 * getHoliday(new Date());
 * getHoliday('2025-01-01');
 * getHoliday(dayjs());
 */
export default function useHolidays(calendarId) {
  const gapi = useGAPI();
  const [holidaysByYear, setHolidaysByYear] = useState({});
  const { data, trigger } = useSWRMutation([gapi, calendarId], fetcher, { keepPreviousData: true });

  // Fetch holidays for each year when gapi is ready and params are enqueued.
  useEffect(() => {
    const params = Object.values(holidaysByYear).find(params => params.timeMin);
    if (!gapi) { return }
    if (params) { trigger(params) }
  }, [gapi, holidaysByYear]);

  // Store fetched holidays by year on success.
  useEffect(() => {
    if (!data) { return }
    if (data.status == 200) {
      let year;
      const holidays = data.result.items.reduce((index, holiday) => {
        year ||= dayjs(holiday.start.date).year();
        index[holiday.start.date] ||= holiday;
        return index;
      }, {});

      setHolidaysByYear((current) => ({ ...current, [year]: holidays }));
      return;
    }

    setHolidaysByYear((current) => ({ ...current, [year]: { error: data } }));
  }, [data, setHolidaysByYear]);

  /**
   * @param {Date | string | dayjs.Dayjs} date
   */
  const getHoliday = useCallback((date) => {
    date = dayjs(date);
    const year = date.year();
    const timeMin = date.startOf('year').format('YYYY-MM-DD[T00:00:00.000Z]');
    const timeMax = date.endOf('year').format('YYYY-MM-DD[T23:59:59.999Z]');

    if (holidaysByYear[year] && !holidaysByYear[year].timeMin) {
      const ymd = date.format('YYYY-MM-DD');
      return holidaysByYear[year][ymd];
    }

    if (!holidaysByYear[year]) {
      // enqueue to fetch holidays
      holidaysByYear[year] = { timeMin, timeMax };
      setHolidaysByYear((current) => ({ ...current, [year]: { timeMin, timeMax } }));
    }

    return null;
  }, [gapi, holidaysByYear]);

  return getHoliday;
};
