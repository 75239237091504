import { createContext, useContext } from 'react';
import { useHolidays } from '@src/hooks/shared';
import { useTranslation } from 'react-i18next';

const Context = createContext({});

export const SharedDataProvider: React.FC = props => {
  const { i18n } = useTranslation();
  const getHoliday = useHolidays(`${i18n.language}.japanese.official#holiday`);

  return <Context.Provider value={{ getHoliday }} {...props} />;
};

export const useSharedData = () => useContext(Context);
